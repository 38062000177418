// Remove the following lines, if you do not need any of Bootstrap's JavaScript features
import Popper from "popper.js";
window.jQuery = $;
window.$ = $;

require("bootstrap");

// Smooth scrolling using jQuery easing
$('a.js-scroll-trigger[href*="#"]:not([href="#"])').on("click", function () {
  if (location.pathname.replace(/^\//, "") == this.pathname.replace(/^\//, "") && location.hostname == this.hostname) {
    var target = $(this.hash);
    target = target.length ? target : $("[name=" + this.hash.slice(1) + "]");
    if (target.length) {
      $("html, body").animate(
        {
          scrollTop: target.offset().top - 72
        },
        1000,
        "easeInOutExpo"
      );
      return false;
    }
  }
});

$(".js-scroll-trigger").on("click", function () {
  $(".navbar-collapse").removeClass("show");
});

// Add class to Nav if scrolled
$(window).on("scroll", function () {
  if ($("#mainNav").offset().top > 100) {
    $("#mainNav").addClass("navbar-scrolled");
  } else {
    $("#mainNav").removeClass("navbar-scrolled");
  }
});

// Scroll spy - Add active class to scrolled menu section
$("body").scrollspy({
  target: "#mainNav",
  offset: 200
});

// PEOPLE SIDEBAR

// Show sidebar on click + toggle visibility of content (based on ID)
$(".people-col").on("click", function (e) {
  e.stopPropagation();
  $(".js__people__all").removeClass("js__people__show");
  $(".people__modal").toggleClass("active");
  var people = $(this).attr("id");
  $("#js__" + people).toggleClass("js__people__show");
});

// Hide sidebar on click on body
$("body").on("click", function (e) {
  $(".js__people__all").removeClass("js__people__show");
  $(".people__modal").removeClass("active");
});

$(".modal__close").on("click", function (e) {
  $(".js__people__all").removeClass("js__people__show");
  $(".people__modal").removeClass("active");
});

// Prevent closing sidebar when cliked inside
$(".people__modal").on("click", function (e) {
  e.stopPropagation();
});

// SCIENCE SIDEBAR

// Show sidebar on click + toggle visibility of content (based on ID)
$(".science-col").on("click", function (e) {
  e.stopPropagation();
  $(".js__science__all").removeClass("js__science__show");
  $(".science__modal").toggleClass("active");
  var science = $(this).attr("id");
  $("#js__" + science).toggleClass("js__science__show");
});

// Hide sidebar on click on body
$("body").on("click", function (e) {
  $(".js__science__all").removeClass("js__science__show");
  $(".science__modal").removeClass("active");
});

$(".modal__close").on("click", function (e) {
  $(".js__science__all").removeClass("js__science__show");
  $(".science__modal").removeClass("active");
});

// Prevent closing sidebar when cliked inside
$(".science__modal").on("click", function (e) {
  e.stopPropagation();
});

// Timeline scroll animation
function autoScrollUp() {
  $(".timeline__wrap")
    .css({ top: 0 }) // jump back
    .animate({ top: -$(".timeline__outer").outerHeight() }, 50000, "linear", autoScrollUp); // and animate
}

$(".timeline__outer").css({ maxHeight: $(".timeline__wrap").height() });
$(".timeline__wrap").html($(".timeline__wrap").html() + $(".timeline__wrap").html());
autoScrollUp();

$(".php__alert-send").hide();

$(".php__btn-send").on("click", function () {
  $(".contact__box--before").addClass("d-none");
  $(".contact__box--after").removeClass("d-none");
});


// Cookies bar
$(document).ready(function () {
  // Funkcia zisti ci existuje cookie
  function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  if (getCookie("cookies") == "clicked") {
    $("#cookie-dialog").addClass("d-none");
  } else {
    $("#cookie-dialog").removeClass("d-none");
  }

  // Funkcia nastavi cookie
  function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + 3 * 30 *24 * 3600000); // cookie expiration (pocet milisekund) 3 months
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  $("#cookie-button").on("click", function () {
    setCookie("cookies", "clicked", 1);
    $("#cookie-dialog").addClass("d-none");
  });

});
